.titles {
    font-size: 20px;
}

.titleDescription {
    font-weight: bold;
    font-size: 24px;
}

.nobots {
    font-weight: bold;
    font-size: 35px;
}

.botCol {
    background-color: rgb(255 215 0)!important;
    border-radius: .50rem!important;
    margin-top: 20px;
    margin-bottom: 20px;
}

.botItemTitle {
    font-weight: bolder;
    font-size: 22px;
}

.titleDescriptionUpgrade {
    font-size: 12px;
}

.botItemTime {
    font-weight: bolder;
    font-size: 18px;
}

.botItemTimeTitle {
    text-align: end;
    margin-right: 20px;
}

.botItemCounterTitle {
    font-size: 18px;
    text-align: end;
    margin-right: 20px;
}

.botItemCounterData {
    font-weight: bolder;
    font-size: 18px;
    text-align: end;
    margin-right: 20px;
}

.botRunItem {
    background-color: rgb(255 255 255)!important;
    color: rgb(1 16 23)!important;
    margin: 30px;
}

.profileInfo {
    text-align: end;
}

@media (min-width: 576px) and (max-width: 991px) {
    .subscriptionCol {
        font-size: 12px;
    }
}

@media (max-width: 576px) {
    .btnRunNow {
        margin-left: 12px;
    }
    .profileInfo {
        text-align: inherit;
    }
}
.accordionNotExpanded:after {
    font-family: 'FontAwesome';
    content: "\f105"; 
    -webkit-text-stroke: 2px;
    color: rgb(0, 0, 0);
  }   
  .accordionExpanded:after {
    font-family: 'FontAwesome';
    content: "\f078";
    color: rgb(0, 0, 0);
}
a,.btn-link {
    text-decoration: none;
}
a:hover,.btn-link:hover {
    text-decoration: underline;
}