.bg-bot-yellow {
    background-color: #fbd301!important;
}

.navbar-brand {
    color: black !important;
}

.navbar-brand img {
    width: 90px;
    height: 50px;
}